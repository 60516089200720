import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-popover',
  templateUrl: './modal-popover.page.html',
  styleUrls: ['./modal-popover.page.scss'],
})
export class ModalPopoverPage implements OnInit {

  constructor(
    private modalCtr: ModalController,
  ) { }

  ngOnInit() {
  }

  async close() {
    /* const closeModal: string = "Modal Closed"; */
    await this.modalCtr.dismiss();
  }
}
